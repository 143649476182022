// Import our CSS
import "@/scss/glightbox.scss";

import GLightbox from "glightbox";

window.GLightbox = GLightbox;

document.addEventListener("DOMContentLoaded", function () {
    let lightboxElements = document.querySelectorAll(".glightbox");

    lightboxElements.forEach((element) => {
        const lightbox = GLightbox({
            selector: "#" + element.getAttribute("id"),
        });

        lightbox.on('open', () => {
            let slider = document.querySelector("#glightbox-slider");
            let customCloseButton = slider.querySelector("#close-lightbox");
            if(customCloseButton) {
                customCloseButton.addEventListener("click", (event) => {
                    lightbox.close();
                });
            }

            if(form = slider.querySelector("form[data-freeform]")) {
                form.addEventListener("freeform-ready", function (event) {
                    var freeform = event.freeform;
                    freeform.setOption("errorClassBanner", ["bg-red-100", "border", "border-red-400", "text-red-700", "px-4", "py-3", "rounded", "relative", "mb-4"]);
                    freeform.setOption("errorClassList", ["errors", "text-red-500", "text-sm", "italic"]);
                    freeform.setOption("errorClassField", ["border-red-500"]);
                    freeform.setOption("successClassBanner", ["bg-green-100", "border", "border-green-500", "text-green-700", "px-4", "py-3", "rounded", "relative", "mb-4"]);
                });

                form.addEventListener('freeform-ajax-success', function (event) {
                    window.setCookie('popupFilled', 'filled', $(".popupform-wrapper").attr('data-success-timeout'));
                    setTimeout(() => {
                        lightbox.close();
                    }, 10000);
                    // lightbox.close();
                    document.querySelector(".popupform-wrapper")?.classList.add('hidden');
                });
            }
        });
    });
});
